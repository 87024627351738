.two-columns-list {
    display: flex;
    flex-wrap: wrap;
    > li {
        width: 50%;
        padding-right: 15px;
        @include media-breakpoint-down(sm) {
            width: 100%;
            padding-right: 0;
        }
    }
}

.default-list {
    li {
        list-style-type: disc;
        list-style-position: inside;
        margin-top: 0.5rem;
    }
}
