.card {
    border: none;
    color: $color3;
    background: none;
    position: relative;
    @include transition(350ms);
    @include box-shadow(5px 5px 10px rgba(0, 0, 0, 0.3));

    &.card-widget {
        .card-header {
            padding-right: 2.25rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &.is-small-card {
        .card-header {
            padding: 0.92rem 1.25rem;
        }
        .card-body {
            padding: 0.85rem 1.25rem;
        }
    }

    &.without-header {
        .card-body {
            @include border-radius(0.4rem);
        }
    }

    &.card-note-active {
        .card-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 8.125rem;
            @include media-breakpoint-down(sm) {
                height: 12rem;
            }
        }

        .card-note {
            white-space: normal;
            max-height: 6rem;
            overflow-y: auto;
        }

        .card-note-toggle {
            @include rotate(180);
        }
    }

    &.in-edit-mode {
        .card-edit-btn {
            visibility: hidden;
        }
        .show-on-edit {
            display: block;
        }
        .hide-on-edit {
            display: none;
        }
    }

    .show-on-edit {
        display: none;
    }

    .card-header {
        position: relative;
        font-weight: bold;
        background-color: $color1;
        text-transform: uppercase;
        border-bottom: 1px solid #33306f;
        padding: 0.92rem 2rem;
        @include media-breakpoint-down(sm) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        &.has-icon {
            padding-left: 3.75rem;
        }
    }

    .card-header-icon-wrap {
        padding-left: 4.6rem;
    }

    .card-widget-icon,
    .card-header-icon {
        right: 1.25rem;
        position: absolute;
        top: 1.1rem;
    }

    .card-widget-icon {
        @include transition(350ms);
        &.active {
            @include rotate(180);
        }
    }

    .card-header-icon {
        right: inherit;
        left: 1.25rem;
        @include vertically-centered();
    }

    .card-body {
        background-color: rgba($color1, 0.8);
        padding: 2rem;
        min-height: 6.5rem;
        @include border-radius(0 0 0.4rem 0.4rem);
        @include media-breakpoint-down(sm) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .card-body-part {
            width: 50%;
            color: #fff;
            .text-wrap {
                border-left: 1px solid $color4;
                padding: 0 1.25rem;
            }
            p {
                line-height: 1.3;
            }
            &:first-child {
                .text-wrap {
                    padding-left: 0;
                    border-left: none;
                }
            }
            &:last-child {
                .text-wrap {
                    padding-right: 0;
                }
            }
        }

        p {
            line-height: 2;
        }

        .card-body-part-heading {
            font-size: 0.8125rem;
            margin-bottom: 0.5rem;
            font-weight: normal;
            color: $color3;
        }
    }

    .card-ribbon {
        position: absolute;
        right: -0.5rem;
        top: -0.35rem;
        z-index: 1;
        color: #fff;
        font-size: 0.66rem;
        font-weight: 800;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.32);
        text-transform: uppercase;
        background: url(../images/icons/card-ribbon.svg) no-repeat;
        background-size: contain;
        height: 4rem;
        width: 4rem;
        span {
            display: block;
            line-height: 3.8;
            text-align: right;
            position: relative;
            top: -0.1rem;
            @include rotate(48);
        }
    }

    .card-body-heading {
        font-size: 1.875rem;
        font-weight: normal;
        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
        }
        &.is-small-heading {
            font-size: 1.5625rem;
            @include media-breakpoint-down(sm) {
                font-size: 1.3rem;
            }
        }
    }

    .card-body-tagline {
        font-size: 1.25rem;
    }

    .card-footer {
        background-color: $color4;
        color: #fff;
        padding: 1.04rem 1.25rem;
        border-top: none;
        position: relative;
        margin-top: -3px;
        @include transition(350ms);
        /*.btn {
            min-width: 6.25rem;
        }*/
        .btn-link {
            min-width: inherit;
        }
    }

    .card-note {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        max-height: 2.2rem;
    }

    .card-note-toggle {
        cursor: pointer;
        /*@include media-breakpoint-up(xl) {
            position: relative;
            top: -0.7rem;
        }*/
    }

    &.theme2 {
        .card-header {
            background-color: $color4;
        }
        .card-body {
            background-color: $color1;
        }
    }

    &.theme3 {
        .card-body {
            background-color: #2b2960;
        }
    }


    &.as-panel {
        max-width: 100%;

        .form-control {
            max-width: 4.5rem;
            height: inherit;
            border-bottom: 1px solid $color3;
            &.big-control {
                max-width: 11rem;
            }
        }

        .border-on-left {
            position: relative;
            &:before {
                content: '';
                height: 100%;
                width: 1px;
                background: #2b2960;
                position: absolute;
                left: -0.5rem;
                top: 0;
            }
            @include media-breakpoint-down(md) {
                &:before {
                    display: none;
                }
            }
        }

        .select2-container--default .select2-selection--single .select2-selection__rendered {
            line-height: 1.2;
            padding-bottom: 0.2rem;
            color: #fff;
        }

        .select2-container--default .select2-selection--single .select2-selection__arrow {
            top: 0;
        }

        .card-body-icon {
            position: absolute;
            left: 2.25rem;
            top: 1.5rem;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .card-body-content {
            padding-left: 6.5rem;
            @include media-breakpoint-down(sm) {
                padding-left: 0;
            }
        }

        .card-body {
            padding: 1.5rem 2rem;
            @include border-radius(0.4rem);
            @include media-breakpoint-down(sm) {
                padding: 1.5rem 1rem;
            }
        }
    }

    &.has-multiple-body {
        .card-body {
            @include border-radius(0);
            &:first-child {
                @include border-radius(0.4rem 0.4rem 0 0);
            }
            &:last-child {
                @include border-radius(0 0 0.4rem 0.4rem);
            }
        }
    }
}


.card-steps {
    .card-step {
        border: 2px solid $color3;
        position: relative;
        margin-left: 1.8125rem;
        display: inline-block;
        z-index: 1;
        @include sphere(0.875rem);
        &:before {
            content: '';
            height: 0.25rem;
            width: 2.1rem;
            left: -2.2rem;
            z-index: -1;
            background: $color3;
            @include vertically-centered();
        }
        &:first-child {
            margin-left: 0;
            &:before {
                display: none;
            }
        }
        &.active-step {
            border: none;
            background: #f9cc45;
            @include box-shadow(3px 3px 6px rgba(243, 170, 60, 0.3));
        }
    }
}

.card-body-band {
    background-color: $color1;
    padding: 0 2rem;
    margin-left: -2rem;
    width: calc(100% + 4rem);
    @include media-breakpoint-down(sm) {
        margin-left: -1rem;
        width: calc(100% + 2rem);
    }
    .band-item {
        margin: 0.5rem 0;
    }
}

.plan-card {
    @include box-shadow(none);
    &:hover {
        @include box-shadow(12px 12px 16px rgba(0, 0, 0, 0.21));

        .btn {
            background-image: linear-gradient(to right, #f9cc45, #f7c041, #f5b33e, #f2a53a);
        }
    }
    .pricing {
        color: #fff;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        .pricing-value {
            font-size: 3.75rem;
            line-height: 1;
        }
        sup {
            top: -2rem;
        }
        sub {
            color: $color3;
            font-size: 0.75rem;
            bottom: 0;
            margin-left: -1.3rem;
        }
    }
    .btn {
        min-width: 9.375rem;
    }
}

.dealership-card {
    &.in-edit-mode {
        .card-footer {
            margin-left: -2rem;
            width: calc(100% + 4rem);
            @include media-breakpoint-down(sm) {
                margin-left: -1rem;
                width: calc(100% + 2rem);
            }
        }
        .card-body {
            padding-bottom: 0;
        }
    }
}

.card-widget {
    font-size: 0.9375rem;
    color: #fff;
    .form-control {
        border-bottom: 1px solid $color3;
    }
    .year-control {
        max-width: 4rem;
        height: 2.56rem;
    }

    .text-carousel {
        height: 2.6rem;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: #fff;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
        color: $color3;
    }
    .btn-info {
        max-width: 6.3rem;
        width: 100%;
    }
}

.text-carousel {
    .text-carousel-result {
        margin: 0 0.6rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        width: 100%;
    }
}

.financial-summary-card {
    &.card.as-panel {
   /* .form-control {
        @include media-breakpoint-down(sm) {
            height: 1rem;
        }
    }*/
        
        @include media-breakpoint-down(sm) {
            .select2-container--default .select2-selection--single {
                height: inherit;
            }
        }
    }
}
