.icon-block {
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 100%;
}


.bell-icon {
    background-image: url(../images/icons/bell.svg);
    width: 1.3125rem;
    height: 1.875rem;
    &.is-small {
        width: 1rem;
        height: 1.275rem;
    }
    &.alt-color {
     background-image: url(../images/icons/bell2.svg);   
    }
}

.dashboard-icon {
    background-image: url(../images/icons/dashboard.svg);
}

.deals-icon {
    background-image: url(../images/icons/deals.svg);
    width: 1.875rem;
    height: 1.875rem;
}

.associates-icon {
    background-image: url(../images/icons/associates.svg);
}

.clients-icon {
    background-image: url(../images/icons/clients.svg);
}

.car-icon {
    background-image: url(../images/icons/dealerships.svg);
    width: 1.625rem;
    height: 1.25rem;
    &.is-big {
        width: 2.8125rem;
        height: 2.1875rem;
    }
}

.documents-icon {
    background-image: url(../images/icons/documents.svg);
    &.is-small {
        width: 1.5rem;
        height: 1.8125rem;
    }
    &.theme2 {
        background-image: url(../images/icons/documents2.svg);
    }
}

.financial-summary-icon {
    background-image: url(../images/icons/financial-summary.svg);
}

.sold-icon {
    background-image: url(../images/icons/sold.svg);
    width: 1.75rem;
    height: 1.5625rem;
}

.coin-icon {
    background-image: url(../images/icons/coin.svg);
    width: 1.5rem;
    height: 1.5rem;
    &.is-big {
     width: 2.5rem;
     height: 2.5rem;   
    }
}

.fire-icon {
    background-image: url(../images/icons/fire.svg);
    width: 1rem;
    height: 1.4rem;
}

.sandclock-icon {
    background-image: url(../images/icons/sandclock.svg);
    width: 1rem;
    height: 1.375rem;
}

.login-icon {
    background-image: url(../images/icons/login.svg);
    width: 1.5rem;
    height: 1.5rem;
}

.signup-icon {
    background-image: url(../images/icons/signup.svg);
    width: 1.6875rem;
    height: 1.6875rem;
}

.envelope-icon {
    background-image: url(../images/icons/envelope.svg);
    width: 1.3125rem;
    height: 1rem;
    &.is-big {
     width: 2.75rem;
    height: 2.125rem;
    }
}

.lock-icon {
    background-image: url(../images/icons/lock.svg);
    width: 1.125rem;
    height: 1.5rem;
}

.tag-icon {
    background-image: url(../images/icons/tag.svg);
    width: 1.6875rem;
    height: 1.6875rem;
}

.credit-card-icon {
    background-image: url(../images/icons/credit-card.svg);
    width: 2.125rem;
    height: 1.4375rem;
}

.user-icon {
    background-image: url(../images/icons/user.svg);
    width: 1.375rem;
    height: 1.6875rem;
}

.user-circle-icon {
    background-image: url(../images/icons/user-circle.svg);
    width: 2.875rem;
    height: 2.875rem;
}

.plus-circle-icon {
    background-image: url(../images/icons/plus-circle.svg);
    width: 1.75rem;
    height: 1.75rem;
}

.save-icon {
    background-image: url(../images/icons/save.svg);
    width: 1.375rem;
    height: 1.375rem;
}

.phone-icon {
    background-image: url(../images/icons/phone.svg);
    width: 1.5625rem;
    height: 1.5625rem;
    &.is-big {
        width: 2.875rem;
        height: 2.875rem;
    }
}

.task-icon {
    background-image: url(../images/icons/task.svg);
    width: 1.625rem;
    height: 1.4375rem;
    &.is-big {
        width: 2.5rem;
        height: 2.3rem;
    }
}

.dollar2-icon {
    background-image: url(../images/icons/dollar2.svg);
    width: 2rem;
    height: 1.625rem;
    &.is-big {
        width: 3.75rem;
        height: 3.125rem;
    }
}

.note-icon {
    background-image: url(../images/icons/note.svg);
    width: 1.5rem;
    height: 1.5rem;
    &.is-big {
        width: 2.5rem;
        height: 2.625rem;
    }
}

.listing-icon {
    background-image: url(../images/icons/listing.svg);
    width: 2.5rem;
    height: 2.125rem;
    background-size: contain;
    &[aria-expanded="true"] {
        background-image: url(../images/icons/mini.svg);
    }
}

.invoice-icon {
    background-image: url(../images/icons/invoice.svg);
    width: 2.125rem;
    height: 2.34rem;
    &.alt-color {
        background-image: url(../images/icons/invoice-alt.svg);
    }
}

.w9-doc-icon {
    background-image: url(../images/icons/w9-doc.svg);
    width: 1.625rem;
    height: 2.0625rem;
    &.alt-color {
        background-image: url(../images/icons/w9-doc-alt.svg);
    }
}

.reminder-icon {
    background-image: url(../images/icons/reminder.png);
    width: 1.4375rem;
    height: 0.9375rem;
}
