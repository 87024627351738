.custom-input-file {
    position: relative;
    display: inline-block;
    
    .inputfile {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .selected-value {
        position: relative;
        z-index: 1;
        padding: 0.3125rem 0.8rem;
        max-width: 250px;
        border: 1px solid $color2;
        color: $color2;
        font-weight: normal;
        display: none;
        margin-top: 1rem;
        @include border-radius(1.063rem);
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    .text {
      white-space: nowrap;
        overflow: hidden;  
        text-overflow: ellipsis;
        display: block;
        max-width: 200px;
        @include media-breakpoint-down(sm) {
            max-width: 92%;
        }
    }
    .input-close {
        right: 15px;
        cursor: pointer;
        font-size: 20px;
        color: $color2;
        @include vertically-centered();
    }
}
