/* Bootstrap */
@import 'bootstrap/bootstrap';


/* Animate */
@import 'modules/animate';


/* Mixins */
@import 'mixins/transform';
@import 'mixins/triangle';
@import 'mixins/utilities';

/* Utilities */
@import 'utilities/variables';
@import 'utilities/settings';
@import 'utilities/icons';
@import 'utilities/generic-classes';

/* Layout */
@import 'layout/header';
@import 'layout/dashboard';
@import 'layout/footer';

/* Modules */
@import 'modules/buttons';
@import 'modules/form';
@import 'modules/datepicker';
@import 'modules/custom-file-input';
@import 'modules/selectbox';
@import 'modules/checkbox';
@import 'modules/card';
@import 'modules/box';
@import 'modules/dropdown';
@import 'modules/menu-trigger';
@import 'modules/main-navigation';
@import 'modules/social-icons';
@import 'modules/lists';
@import 'modules/tab-navigation';
@import 'modules/table';
@import 'modules/searchbar';
@import 'modules/progress-bar';
@import 'modules/modal';
@import 'modules/scrollbar';


/* CSS code from Developer */
@import 'utilities/developer-codes';


/* Cross Browser code if any */
@import 'utilities/cross-browsers';