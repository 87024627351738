.box {
    color: #fff;
    .box-header, .box-content {
        padding: 0.73rem 1.3rem;
        background-color: $color4;
        @include media-breakpoint-up(xxl) {
            padding: 0.73rem 2.125rem;
        }
    }
    
    &.no-box-content {
        .box-header {
            @include border-radius(0.5rem);
        }
    }
    
    .box-header {
        border-bottom: 1px solid transparent;
        @include border-radius(0.5rem 0.5rem 0 0);
    }
    
    .box-content {
        position: relative;
        top: 3px;
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
        @include border-radius(0 0 0.5rem 0.5rem);
    }
    
    .heading-text {
        color: $color3;
        text-transform: uppercase;
        font-weight: normal;
    }
}