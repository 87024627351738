.custom-select {
    position: relative;
    background-color: #1e2229;
    z-index: 1;
    &:after {
        content: "\f107";
        font-family: 'Font Awesome 5 Free';
        position: absolute;
        font-size: 1.2rem;
        right: 1rem;
        color: #fff;
        z-index: -1;
        font-weight: 700;
        @include vertically-centered();
    }

    &.light-theme {
        background-color: #fff;
        &:after {
            color: $color1;
        }
        select {
            color: $color1;
        }
    }

    &.form-control {
        select {
            @include media-breakpoint-down(sm) {
                height: 2.5rem;
                padding: 0.5rem;
            }
        }
    }
    select {
        cursor: pointer;
        outline: none;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border: none;
        color: #fff;
        height: 100%;
        padding: 0.5rem 1.1rem;
        background: transparent;
        font-size: 0.9375rem;
        option {
            color: $theme-green;
        }
    }
}


// Select 2
.select2-container--default .select2-selection--single .select2-selection__arrow {
    position: absolute;
    right: -0.5rem;
    top: 0.5rem;
    &:before {
        content: "\f107";
        font-family: 'Font Awesome 5 Free';
        font-weight: 700;
    }
    b {
        display: none;
    }
}

.select2-container--default .select2-selection--single {
    border: none;
    font-size: 1rem;
    background: none;
    height: 2.5rem;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $color3;
    line-height: 2.5rem;
    border-bottom: 1px solid $color3;
    padding-left: 0;
}

.select2-container--open .select2-dropdown {
    border: none;
    min-width: 11rem;
    background: #5b58b2;
    @include border-radius(0.5rem);
}

.select2-container--default .select2-results > .select2-results__options {
    padding: 0;
}

.select2-results__option {
    font-size: 1rem;
    color: $color3;
    padding: 0.65rem 1.5rem;
    background: none;
    &:first-child {
        @include border-radius(0.5rem 0.5rem 0 0);
    }
    &:last-child {
        @include border-radius(0 0 0.5rem 0.5rem);
    }
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected="true"] {
    color: #fff;
    background: linear-gradient(to right, #a161f6, #9460f6, #8260f7, #735ff7);
}


.selectbox-wrap {
    .selectbox-label {
        margin-right: 0.6rem;
        font-weight: normal;
        color: $color3;
        font-size: 0.9375rem;
        margin-bottom: 0;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        top: 0.7rem;
    }
}

.select2-container * {
    outline: none !important;

}

.theme-selectbox {

    &.full-width {
        + .select2-container--default {
            width: 100% !important;
        }
    }
    &.theme2 {
        + .select2-container--default .select2-selection--single {
            font-size: 0.9375rem;

            .select2-selection__arrow:before {
                color: $color3;
            }
            .select2-selection__rendered {
                border: none;
                color: #fff;
            }
        }

        &.with-border {
            + .select2-container--default .select2-selection--single {
                .select2-selection__rendered {
                    border-bottom: 1px solid $color3;
                }
            }
        }
    }
}


.reminder-selectbox {
    margin-top: 0.7rem;
    position: relative;
    margin-bottom: 3.5rem;
    display: none;
    z-index: 2;
    &:after {
        content: '';
        height: 4px;
        left: -1.3rem;
        width: calc(100% + 2.6rem);
        background-color: $color4;
        position: absolute;
        bottom: -2rem;
    }
    .theme-selectbox {
        width: 8rem;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        border-color: $color4;
    }
}

/*
.financial-summary-card {
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 1.5 !important;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
        top: 0.3rem !important
    }
}*/