#footer {
    font-size: 0.8125rem;
    padding: 1.625rem 0;
    color: $color1;
    
    ul, ol {
        @extend .clean-list;
    }

    .footer-links {
        margin-bottom: 1.5rem;
        margin-top: 1.3rem;
        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }
        li {
            font-size: 1rem;
            text-transform: uppercase;
            a {
                color: $color1;
                &:hover {
                    color: $color2;
                    text-decoration: underline;
                }
            }
            &:before {
                content: '/';
                padding: 0 0.4rem;
            }

            &:first-child {
                &:before {
                    display: none;
                }
            }
        }
    }

}
