.tab-navigation {

    .tab-head {
        display: flex;
    }
    .tab-head-item {
        cursor: pointer;
        font-size: 0.9375rem;
        border-bottom: 3px solid transparent;
        margin-left: 3rem;
        padding-bottom: 0.5625rem;
        &:first-child {
            margin-left: 0;
        }
        &.active {
            color: #f2a43a;
            font-weight: 700;
            border-bottom-color: #f2a43a;
        }
    }

    .tab-pane {
        display: none;

        &.active {
            display: block;
        }
    }

    .card.is-small-card {
        max-width: 22.5rem;
    }

    .btn-anim {
        min-width: 16.875rem;
    }

}

.dealerships-tab {
    tr.overdue {
        .cell-border {
            background-color: $theme-red;
        }
    }
}
