#dashboard-content {
    @include media-breakpoint-down(xl) {
        padding: 2rem;
    }
    @include media-breakpoint-down(lg) {
        padding: 1rem;
    }
    @include media-breakpoint-down(md) {
        margin-top: 4rem;
    }
    .dashboard-content-container {
        @include media-breakpoint-down(xl) {
            padding: 0;
        }
    }
}

#dashboard-sidebar,
#dashboard-main {
    min-height: calc(100vh - 5rem);
}

#dashboard-sidebar {
    background-color: rgba($color1, 0.8);
    height: 100%;
    width: 17.5rem;
    position: fixed;
    left: -18rem;
    top: 7,2rem;
    z-index: 10;
    @include border-radius(0 .5rem 0 0);
    @include transition(350ms);
    @include media-breakpoint-down(xl) {
        background-color: $color1;
        top: 4.5rem;
        @include border-radius(0);
    }
    &.active {
        left: 0;
    }
}

#dashboard-main {
    width: 100%;
    padding-top: 2rem;
    @include transition(350ms);
    &.menu-active {
        @include media-breakpoint-up(xxl) {
        margin-left: 18.5rem;
        width: 84%;
        }
    }
    .card-header-icon-wrap {
        padding-left: 3.7rem;
        @include media-breakpoint-down(sm) {
            padding-left: 15px;
            .card-header-icon {
                display: none;
            }
        }
    }
    
    .searchbar {
        max-width: 25rem;
    }
}


.sidebar-navigation {
    padding: 2rem 0;
    > li {
        margin-bottom: 0.5rem;
        &:last-child {
            margin-bottom: 0;
        }
        > a {
            display: block;
            position: relative;
            color: $color3;
            border-left: .375rem solid transparent;
            padding: 0.92rem 1.5rem 0.92rem 4.4375rem;
            @include transition(350ms);
            &.active,
            &:hover {
                color: #fff;
                background-color: #2c2963;
                -webkit-border-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2a43a), to(rgba(249, 205, 69, 1))) 1 100%;
                -webkit-border-image: -webkit-linear-gradient(#f2a43a, rgba(249, 205, 69, 1)) 1 100%;
                -moz-border-image: -moz-linear-gradient(#f2a43a, rgba(249, 205, 69, 1)) 1 100%;
                -o-border-image: -o-linear-gradient(#f2a43a, rgba(249, 205, 69, 1)) 1 100%;
                border-image: linear-gradient(to bottom, #f2a43a, rgba(249, 205, 69, 1)) 1 100%;
            }
        }
    }

    .navicon {
        position: absolute;
        left: 1.5625rem;
        top: 0.66rem;
        background-size: contain;
        background-position: left center;
        @include square(1.875rem);
    }
}

.dashboard-header-panel {
    .header-panel-item {
        border-left: 1px solid #33306f;
        padding: 0 1.125rem;
        @include media-breakpoint-down(sm) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            position: static;
            .dropdown-menu {
                width: 100%;
                margin-top: 3.5rem;
                @include transform(none !important);
            }
        }
        &:first-child {
            padding-left: 0;
            border-left: none;
        }
        &:last-child {
            padding-right: 0;
        }

        .bell-icon {
            margin-right: 1.5rem;
        }

        .user-panel-text {
            display: inline-block;
            margin-right: 1.5rem;
            position: relative;
            padding-right: 1.5rem;
            @include media-breakpoint-down(sm) {
                display: none;
            }
            .text-icon {
                right: 0;
                @include vertically-centered();
            }
        }
    }
}
