.form-check {
    position: relative;
    display: inline-block;
    padding-left: 0;
    .form-check-label {
        display: block;
        position: relative;
        padding-left: 1.875rem;
        margin-bottom: 0;
        font-size: 0.875rem;
        color: #161616;
        font-weight: normal;
        text-align: left;
        .label-icon {
            margin-right: 1rem;
            font-size: 1.125rem;
            color: $color3;
        }
        a {
            position: relative;
            z-index: 1;
        }
        &:before {
            content: "\f0c8";
            font-family: 'Font Awesome 5 Free';
            display: inline-block;
            position: absolute;
            left: 0;
            top: -0.3rem;
            font-size: 1.3rem;
            color: $color3;
        }
    }
    .form-check-input {
        opacity: 0;
        position: absolute;
        left: 0;
        z-index: 1;
        cursor: pointer;
        margin: 0;
        @include square(100%);
        &:checked + label:before {
            content: "\f14a";
            font-weight: 700;
        }
    }


    &.is-radiobox {
        .form-check-label {
            padding-left: 1.375rem;
            &:before {
                content: "" !important;
                background: url(../images/icons/radiobox.svg) no-repeat;
                background-size: 100%;
                top: 0.2rem;
                @include sphere(1rem);
            }
        }

        .form-check-input {
            &:checked + label:before {
                background-image: url(../images/icons/radiobox-checked.svg);
            }
        }
    }
    
    &.check-on-right {
        .form-check-label {
            padding-left: 0;
            padding-right: 1.875rem;
            &:before {
                left: inherit;
                right: 0;
            }
        }
    }
    
    &.has-empty-label {
        height: 1.75rem;
        width: 1.75rem;
        .form-check-label {
            padding: 0;
            &:before {
                top: 0.4rem;
            }
        }
    }

}

.form-check-list {
    .form-check {
        display: block;
        margin-bottom: 1.25rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
