.form {
    .field-validation-error {
        font-size: 0.8125rem;
        margin: 0.3125rem 0;
        line-height: 1.2;
        margin-top: 0.4rem;
        color: $theme-red;
    }
    .form-control {
        background: none;
        color: #fff;
        font-size: 1rem;
        padding: 0;
        border: none;
        @include box-shadow(none !important);
        @include border-radius(0);
        &.theme2 {
            background-color: $color1;
            border: none;
            padding: 0.4rem 1rem;
            @include border-radius(1.5rem);
        }
        @include media-breakpoint-down(sm) {
            height: 2.5rem;
            padding: 0.5rem;
        }

    }

    .form-group-label {
        font-size: 1rem;
        line-height: 1;
        font-weight: normal;
    }
    .form-group {
        margin-bottom: 1rem;
        position: relative;
        height: 2.5rem;
        border-bottom: 1px solid #515091;

        &.has-icon {
            .form-control {
                padding-left: 2.3rem;
            }
        }
    }
    textarea {
        resize: none;
        min-height: 8.125rem;
        @include media-breakpoint-down(sm) {
            min-height: 90px;
        }
    }

    .form-submit {
        min-width: 8.125rem;
    }

    .form-group-icon {
        position: absolute;
        bottom: 0.75rem;
        left: 0;
        color: #9e9e9e;
        font-size: 1.125rem;
    }
}

input,
textarea,
.form-control {
    outline: none !important;
    &::-webkit-input-placeholder {
        opacity: 1;
        color: $color3;
    }
    &::-moz-placeholder {
        opacity: 1;
        color: $color3;
    }
    &:-ms-input-placeholder {
        opacity: 1;
        color: $color3;
    }
    &:-moz-placeholder {
        opacity: 1;
        color: $color3;
    }
}

.form-inline {
    .form-group {
        margin-left: 1rem;
        &:first-child {
            margin-left: 0;
        }
    }
}

.single-line-form {
    position: relative;
    height: 3.5rem;
    background: #fff;
    @include transition(350ms);

    &:hover {
        border: 1px solid $text-color;
        .btn {
            background-color: $text-color;
        }
    }
    @include media-breakpoint-down(xs) {
        height: auto;
    }
    .form-input {
        background: none;
        height: 100%;
        border: none;
        @include media-breakpoint-down(xs) {
            height: 3rem;
        }
        @include box-shadow(none !important);
    }
    .form-btn {
        position: absolute;
        right: 4px;
        top: 3px;
        width: 100%;
        max-width: 10rem;
        height: calc(100% - 6px);
        @include media-breakpoint-down(xs) {
            width: 100%;
            position: static;
            max-width: 100%;
        }
    }

    &.theme2 {
        background-color: #f5f5f5;
        .form-btn {
            top: 0;
            right: 0;
            height: 100%;
        }
    }
}

.single-input-form {
    position: relative;
    &.theme2 {
        .form-control {
            background-color: #515091;
            border: none;
        }
        textarea {
            min-height: 17.5rem;
            @include media-breakpoint-down(sm) {
                min-height: 10rem;
            }
        }
        
        .form-footer-note {
            font-size: 0.75rem;
            position: absolute;
            right: 2rem;
            bottom: 0.7rem;
            z-index: 1;
        }
    }
    &:hover {
        .submit-btn {
            opacity: 1;
        }
    }
    .form-group-icon {
        position: absolute;
        left: 1.25rem;
        top: 1rem;
        color: $color3;
    }
    .submit-btn {
        position: absolute;
        right: 0.625rem;
        bottom: 0.75rem;
        z-index: 1;
        opacity: 0;
        @include transition(350ms);
    }
    .form-control {
        border: 1px solid #423f8c;
        padding: 0.7rem 1rem 0.7rem 3rem;
        @include border-radius(0.4rem);
    }
    textarea {
        min-height: 8.75rem;
    }
}


.form-animated {
    .form-group {
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        height: inherit;
        &:before {
            content: "";
            background-color: #fff;
            width: 0;
            height: 2px;
            position: absolute;
            left: 0;
            bottom: -2px;
            @include transition(400ms);
        }
        &.no-anim {
            border: none;
            &:before {
                display: none !important;
            }
        }
        &.input-filled {
            &:before {
                width: 100%;
                background-color: #fff;
            }
            .form-group-label {
                bottom: 80%;
                color: #fff;
                font-size: 0.8rem;
            }
        }
        
        &.icon-on-right {
            .form-group-icon {
                left: inherit;
                right: 0;
            }
            .form-control {
                padding-left: 0;
                padding-right: 1.5rem;
            }
            .form-group-label {
                left: 0;
            }
        }

        &.without-icon {
            .form-group-label {
                left: 0;
            }
            .form-control {
                padding-left: 0;
            }
        }
    }

    .form-group-submit {
        border: none !important;

        .btn {
            padding-bottom: 0.8rem;
            padding-top: 0.8rem;
            min-width: 8.125rem;
        }
        &:before {
            display: none !important;
        }
    }

    .form-group-label {
        position: absolute;
        left: 2.25rem;
        bottom: 0.75rem;
        font-size: 1rem;
        font-weight: normal;
        color: $color3;
        margin-bottom: 0 !important;
        @include transition(400ms);
    }
    .form-control {
        border: none !important;
        z-index: 1;
        position: relative;
        padding: 1rem 1.5rem 0 2.5rem;
        background: none !important;
        @include box-shadow(none !important);
        &.small-control {
            max-width: 5rem;
            height: inherit;
            padding: 0;
        }
    }



    &.theme2,
    &.theme3 {
        .form-group {
            border-bottom: 2px solid #fff;
        }
        .form-group-icon {
            color: #fff;
        }
        .form-group-label {
            color: #c4e7e6;
            left: 1.7rem;
        }
    }


    &.theme3 {
        .form-group {
            &.input-filled {
                &:before {
                    background-color: darken($color1, 20%);
                }
            }
        }
        .form-control {
            color: darken($color1, 20%);
        }
        .form-group-label {
            color: #fff;
            left: 1.7rem;
        }
    }
    
    &.with-labels {
        .form-group.input-filled .form-group-label {
            opacity: 0;
        }
    }
}


.form-box-wrap {
    max-width: 27.5rem;
}

.form-box {
    .form-box-header {
        background-color: $color1;
        text-align: center;
        color: #fff;
        font-size: 1.25rem;
        font-weight: 700;
        text-transform: uppercase;
        padding: 0.7rem 1rem;
        position: relative;
        &:before {
            content: '';
            background: url(../images/icons/form-box-corner.svg) no-repeat;
            background-size: 100%;
            position: absolute;
            left: 0;
            top: 0;
            @include square(2.5625rem);
        }
    }

    .form-box-content {
        border: 2px solid $color1;
        padding: 2.5rem 3.75rem;
        @include media-breakpoint-down(sm) {
            padding: 2rem 1rem;
        }
    }

    .form-box-footer {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: center;
    }


    &.theme2 {
        .form-box-header {
            background-color: $theme-green;
        }
        .form-box-content {
            border-color: $theme-green;
        }
    }
}
