@mixin triangle($direction, $color: $color1, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: 0;
  height: 0;
  content: '';
  z-index: 2;
border-#{$direction}: $size solid $color;
  /*border-#{opposite-position($direction)}: ($size * 1.5) solid $color;*/
  
  $perpendicular-borders: $size solid transparent;
  
  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }
}
