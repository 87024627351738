.searchbar {
    background-color: #3c397c;
    height: 2.875rem;
    position: relative;
    @include border-radius(2rem);
    .search-input {
        outline: none !important;
        border: none;
        background: none;
        width: 100%;
        height: 100%;
        color: $color3;
        padding: 0.2rem 3.5rem 0.2rem 1.2rem;
    }
    .search-btn {
        color: #fff;
        border: none;
        position: absolute;
        right: 3px;
        top: 3px;
        cursor: pointer;
        @extend .flex-centered;
        @include sphere(2.5rem);
        @include transition(350ms);
        background: #a061f6;
background: -moz-linear-gradient(left, #a061f6 0%, #9560f6 28%, #8860f5 47%, #745ff8 86%, #735ef7 100%);
background: -webkit-linear-gradient(left, #a061f6 0%,#9560f6 28%,#8860f5 47%,#745ff8 86%,#735ef7 100%);
background: linear-gradient(to right, #a061f6 0%,#9560f6 28%,#8860f5 47%,#745ff8 86%,#735ef7 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a061f6', endColorstr='#735ef7',GradientType=1 );
    
        &:hover {
            background: #a061f6;
        }
    }
}