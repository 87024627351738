.mt-5px {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-17 {
    margin-top: 1.0625rem;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 1.5625rem;
}

.mt-30 {
    margin-top: 1.875rem;
}

.mt-35 {
    margin-top: 2.1875rem;
}

.mt-40 {
    margin-top: 2.5rem;
}

.mt-50 {
    margin-top: 3.125rem;
}

.mt-60 {
    margin-top: 3.75rem;
}

.mt-80 {
    margin-top: 5rem;
}

.mt-100 {
    margin-top: 6.25rem;
}

.mb-5px {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 0.625rem;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-80 {
    margin-bottom: 5rem;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-32 {
    margin-bottom: 2rem;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-150 {
    margin-bottom: 150px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-5px {
    margin-left: 5px;
}

.ml-15 {
    margin-left: 0.9375rem;
}

.ml-20 {
    margin-left: 1.25rem;
}

.ml-30 {
    margin-left: 1.875rem;
}

.ml-40 {
    margin-left: 40px;
}

.ml-50 {
    margin-left: 3.125rem;
}

.ml-60 {
    margin-left: 60px;
}

.ml-80 {
    margin-left: 5rem;
}

.mr-5px {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 0.625rem;
}

.mr-15 {
    margin-right: 0.9375rem;
}

.mr-20 {
    margin-right: 20px;
}

.mr-25 {
    margin-right: 1.5625rem;
}

.mr-30 {
    margin-right: 1.875rem;
}

.mr-40 {
    margin-right: 2.5rem;
}

.mr-50 {
    margin-right: 3.125rem;
}

.mr-90 {
    margin-right: 5.625rem;
}

.mb-50 {
    margin-bottom: 50px;
}

.pt-25 {
    padding-top: 1.5625rem;
}

.pt-30 {
    padding-top: 1.875rem;
}

.pr-5 {
    padding-right: 5px;
}

.pr-10 {
    padding-right: 0.625rem;
}

.pr-15 {
    padding-right: 15px;
}

.pr-18 {
    padding-right: 1.125rem;
}

.pr-30 {
    padding-right: 30px;
}

.pl-15 {
    padding-left: 0.9375rem !important;
}

.pl-30 {
    padding-left: 1.875rem;
}

.pl-10 {
    padding-left: 0.625rem;
}

.pb-30 {
    padding-bottom: 1.875rem !important;
}

.pb-20 {
    padding-bottom: 1.25rem;
}

.pb-60 {
    padding-bottom: 3.75rem;
}

.light-font {
    font-weight: 300 !important;
}

.normal-font {
    font-weight: 400 !important;
}

.medium-font {
    font-weight: 500 !important;
}

.semibold-font {
    font-weight: 600 !important;
}

.bold-font {
    font-weight: 700 !important;
}

.italic-font {
    font-style: italic;
}

.fs-13 {
    font-size: 0.8125rem;
}

.fs-14 {
    font-size: 0.875rem !important;
}

.fs-15 {
    font-size: 0.9375rem;
}

.fs-16 {
    font-size: 1rem;
}

.fs-18 {
    font-size: 1.125rem;
}

.fs-20 {
    font-size: 1.25rem;
}

.fs-24 {
    font-size: 1.5rem;
}

.static {
    position: static;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-transform-none {
    text-transform: none !important;
}

.centered {
    margin-right: auto;
    margin-left: auto;
    display: table;
}

.gray-text-small {
    font-size: 0.875rem;
    color: #9e9e9e;
}

.collapse {
    display: none;
    &.show {
        display: block;
    }
}

tr.collapse.show {
    display: table-row;
}

.collapse-trigger {
    &.with-arrow {
        position: relative;
        padding-right: 1.4rem;
        &[aria-expanded="true"] {
            &:after {
                @include rotate(-180);
            }
        }
        &:after {
            content: "\f107";
            font-family: 'Font Awesome 5 Free';
            font-weight: 700;
            position: absolute;
            right: 0;
            top: 2px;
            @include transition(350ms);
        }
    }
}

.divider {
    position: relative;
    border-bottom: 2px solid $color1;
    .divider-icon {
        position: absolute;
        z-index: 1;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .divider-icon-left {
        left: -2.5rem;
        top: 0.5rem;
        @include media-breakpoint-down(xl) {
            left: -1.5rem;
            top: -0.5rem;
        }
    }

    .divider-icon-right {
        right: 0.3rem;
        top: -0.4rem;
    }
}

.user-ratings {
    .user-ratings-item {
        color: #e4bf78;
        &.light-font {
            color: #9e9e9e;
        }
    }
}


.heading-as-divider {
    position: relative;
    &:before {
        content: '';
        height: 1px;
        width: 100%;
        left: 0;
        background-color: $border-color;
        @include vertically-centered();
    }

    span {
        font-size: 1.125rem;
        background-color: #fff;
        padding: 0 1rem;
        position: relative;
        z-index: 1;
        display: table;
        margin-right: auto;
        margin-left: auto;
    }
}

.info-text {
    position: relative;
    padding-left: 2.3rem;
    .info-text-icon {
        color: #fff;
        position: absolute;
        left: 0;
        top: 0.2rem;
    }
}


.bell-count {
    background-color: #ea5734;
    color: #fff;
    font-size: .8125rem;
    @extend .flex-centered;
    @include sphere(1.25rem);
}


.auto-width {
    width: auto !important;
}

.cursor-pointer {
    cursor: pointer;
}

.flex-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-1 {
    flex: 1 !important;
}

.relative-element {
    position: relative !important;
}

.static-element {
    position: static !important;
}

.full-width {
    width: 100% !important;
}

.half-width {
    width: 50% !important;
}

.full-height {
    height: 100% !important;
}

.full-max-width {
    max-width: 100% !important;
}

.full-min-height {
    min-height: 100% !important;
}

.text-link {
    text-decoration: underline;
    font-size: 0.875rem;
    color: $text-color;
    &:hover {
        color: $color2;
        text-decoration: none;
    }
}

.text-success {
    color: $theme-green !important;
}

a.text-success:hover,
a.text-success:focus {
    color: $theme-green !important;
}

.text-danger {
    color: $theme-red !important;
}

.bg-success {
    background-color: $theme-green !important;
}

.bg-danger {
    background-color: $theme-red !important;
}


.gutter-space-18 {
    margin-left: -18px;
    margin-right: -18px;

    *[class*='col-'] {
        padding-left: 18px;
        padding-right: 18px;
    }
}

.gutter-space-14 {
    margin-left: -14px;
    margin-right: -14px;

    *[class*='col-'] {
        padding-left: 14px;
        padding-right: 14px;
    }
}


.gutter-space-4 {
    margin-left: -4px;
    margin-right: -4px;

    *[class*='col-'] {
        padding-left: 4px;
        padding-right: 4px;
    }
}


.big-text {
    font-size: 1.25rem;
    @include media-breakpoint-down(sm) {
        font-size: 18px;
    }
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-scroll-x {
    overflow-x: scroll !important;
}

.block-element {
    display: block;
}

.wow {
    visibility: hidden;
}

.hidden-before-ready {
    visibility: hidden;
}

.no-border {
    border: none !important;
}

.no-shadow {
    @include box-shadow(none !important);
}

.underline-on-hover {
    &:hover {
        text-decoration: underline;
    }
}

.border-bottom-only {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.border-top {
    border-top: 1px solid $border-color;
}

.no-bg {
    background: none !important;
}

.z-index-1 {
    z-index: 1;
}

.sticky {
    position: fixed !important;
    z-index: 100 !important;
}

.clear-both {
    clear: both;
}

.clean-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.bottom-0 {
    bottom: 0 !important;
}

.color1 {
    color: $color1 !important;
}

.color2 {
    color: $color2 !important;
}

.color3 {
    color: $color3 !important;
}

.color4 {
    color: $color4 !important;
}

.color5 {
    color: $theme-green !important;
}

.bg-color1 {
    background-color: $color1;
}

.bg-color2 {
    background-color: $color2;
}

.bg-color3 {
    background-color: $color3;
}

.light-gray-color {
    color: #9e9e9e;
}

.text-color {
    color: $text-color !important;
}

.white-color {
    color: #ffffff !important;
}

.white-bg {
    background-color: #ffffff !important;
}

.gray-color {
    color: #555;
}

.overflow-y {
    overflow-y: scroll;
}

.underline {
    text-decoration: underline;
}


.no-border {
    border: none !important;
}

.no-border-bottom {
    border-bottom: none !important;
}

.no-border-top {
    border-top: none !important;
}

.overflow-visible {
    overflow: visible !important;
}

.morelink-wrap .morecontent span {
    display: none;
}

.morelink-arrow {
    color: $color1;
    font-size: 1.2rem;
    position: relative;
    top: 2px;
}


.alert {
    font-size: 1.125rem;
    color: #fff;
    position: relative;
    text-align: center;
    background-color: #a2d3d1;
    padding: 0.4rem 1rem;
    @include border-radius(0.25rem);
    .alert-close-btn {
        right: 0.75rem;
        cursor: pointer;
        @include vertically-centered();
        @include transition(350ms);
        &:hover {
            color: $text-color;
        }
    }
}

.fs-overlay {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.7);
}

// Media Queries
@media (min-width: 1200px) and (max-width: 2000px) {
    .parallax-bg {
        background-attachment: fixed;
    }
}

@include media-breakpoint-up(xl) {

    .pr-10-xl {
        padding-right: 10px !important;
    }
    .pl-10-xl {
        padding-left: 10px !important;
    }


    .ml-xl-40 {
        margin-left: 2.5rem;
    }

    .flex-column-reverse-xl {
        flex-direction: column-reverse !important;
    }
}

@include media-breakpoint-down(lg) {
    .border-none-lg {
        border: none !important
    }

    .mt-30-lg {
        margin-top: 30px;
    }

    .mt-40-lg {
        margin-top: 2.5rem;
    }
    
    .mt-20-lg {
        margin-top: 20px;
    }

    .mt-0-lg {
        margin-top: 0 !important;
    }

    .mb-50-lg {
        margin-bottom: 50px;
    }
}

@include media-breakpoint-down(md) {

    .mt-60-md {
        margin-top: 60px !important;
    }

    .mt-20-md {
        margin-top: 20px !important;
    }

    .mt-40-md {
        margin-top: 40px !important;
    }

    .mb-15-lg {
        margin-bottom: 15px !important
    }

}

@include media-breakpoint-down(sm) {

    .pt-30-sm {
        padding-top: 30px !important;
    }
    .mb-20-sm {
        margin-bottom: 20px;
    }
    .mt-20-sm {
        margin-top: 20px !important;
    }
    .mt-0-sm {
        margin-top: 0 !important;
    }
    .mt-40-sm {
        margin-top: 2.5rem;
    }
    
    .mb-10-sm {
        margin-bottom: 10px !important;
    }

    .mb-30-sm {
        margin-bottom: 30px;
    }
    .mb-15-small {
        margin-bottom: 15px;
    }
    .mt-10-sm {
        margin-top: 10px;
    }
    
    .text-center-sm {
        text-align: center;
    }

    .mt-30-sm {
        margin-top: 30px !important;
    }

    .mr-15-sm {
        margin-right: 15px;
    }

    .ml-15-sm {
        margin-left: 15px;
    }

    .ml-15-sm {
        margin-left: 15px !important;
    }

    .p-0-sm {
        padding: 0 !important;
    }

    .m-0-sm {
        margin: 0 !important;
    }

    .mr-0-sm {
        margin-right: 0 !important;
    }

    .d-block-sm {
        display: block !important;
    }

    .full-width-sm {
        width: 100% !important;
    }

    .pt-0-sm {
        padding-top: 0 !important;
    }

    .border-none-sm {
        border: none !important;
    }
}

@include media-breakpoint-down(xs) {
    .ml-5-xs {
        margin-left: 5px !important;
    }
    .centered-xs {
        display: table;
        margin-right: auto;
        margin-left: auto;
    }
    .full-width-xs {
        width: 100% !important;
    }
    .mb-10-xs {
        margin-bottom: 10px;
    }
    .mb-20-xs {
        margin-bottom: 20px !important;
    }
    .mb-30-xs {
        margin-bottom: 30px;
    }
    .mt-20-xs {
        margin-top: 20px;
    }
    .mt-30-xs {
        margin-top: 30px;
    }
    .right-10-xs {
        right: 10px !important;
    }
    .mt-15-xs {
        margin-top: 15px;
    }
    .ml-0-xs {
        margin-left: 0 !important;
    }

    .d-block-xs {
        display: block !important;
    }
}
