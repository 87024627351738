.dropdown-toggle {
    cursor: pointer;
    &:after {
        display: none;
    }
}

.dropdown-menu {
    min-width: 18.75rem;
    background: $color1;
    color: $color3;
    margin-top: 2rem;
    @include border-radius(0.5rem);
    &.on-right {
        right: 0;
        left: inherit !important;
        @include transform(none !important);
    }
    &.options-dropdown {
    min-width: 15.625rem;
    right: -2rem;
}
    .dropdown-item-text {
        color: $color3;
        line-height: 1.5;
        padding: 1.25rem;
        textarea {
            background: none;
            resize: none;
            outline: none !important;
            border: none;
            color: $color3;
            width: 100%;
            height: 8.5rem;
        }
    }
    .text-note {
        font-size: 0.75rem;
    }
    .dropdown-menu-footer {
        background-color: $color4;
        padding: 0.5rem 1.25rem;
        .btn {
            max-width: 5rem;
            min-width: inherit;
        }
    }
}

.dropdown-item {
    color: $color3;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &:hover {
        color: $color1;
        background-color: $color3;
    }
}


.table-cell-dropdown {
    .dropdown-menu {
        left: 0 !important;
        margin-top: 5.5rem;
        border: none;
        width: 100%;
        background: none;
        z-index: 2;
        @include border-radius(0);
        @include box-shadow(none !important);
        @include transform(none !important);
    }

    .dropdown-part {
        margin-bottom: 1rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}


.dropdown-block {
    background: #2d2a66;
    padding: 1.1rem 1.5rem;
    margin-bottom: 0.25rem;
    @include media-breakpoint-down(sm) {
        padding: 1rem 0.7rem;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
