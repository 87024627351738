.btn {
    font-size: 0.9375rem;
    padding: 0.86rem 2.2rem;
    position: relative;
    /*min-width: 8.75rem;*/
    border: none;
    color: #fff;
    &:focus,
    &:active,
    &:active:focus,
    &:hover {
        border-color: transparent !important;
        @include box-shadow(none);
    }
    &.btn-primary {
        background-color: #3c397c;
        @include box-shadow(none);
        &:focus,
        &:active,
        &:active:focus,
        &:hover {
            color: #fff;
            background-color: #29265e;
        }
    }

    &.btn-secondary {
        background-image: linear-gradient(to right, #f9cc45, #f7c041, #f5b33e, #f2a53a);
        background-color: transparent;
        padding: 0.86rem 1.5rem;
        font-weight: 700;
        min-width: 8.75rem;
        @include box-shadow(3px 3px 16px rgba(243, 170, 60, 0.4));
        &:hover,
        &:focus,
        &:active {
            background-image: linear-gradient(to right, #f2a53a, #f5b33e, #f7c041, #f9cc45);
            @include box-shadow(3px 3px 6px rgba(243, 170, 60, 0.3));
        }
    }

    &.btn-info {
        background: linear-gradient(to right, #a061f6, #9560f6, #8660f7, #745ff7);
        padding: 0.55rem 1.3rem;
        &:focus,
        &:active,
        &:active:focus,
        &:hover {
            background: linear-gradient(to right, #745ff7, #8660f7, #9560f6, #a061f6);
        }
    }

    &.btn-outline {
        border: 2px solid #fff;
        color: #fff;
        background: none;
        @include box-shadow(none);

        &:focus,
        &:active,
        &:active:focus,
        &:hover {
            background: linear-gradient(to right, #a061f6, #9560f6, #8660f7, #745ff7);
        }
    }

    &.btn-rounded {
        @include border-radius(3rem);
    }


    &.btn-link {
        text-decoration: underline;
        color: $color3;
        min-width: inherit;
        padding: 0;
        border: none !important;
        @include box-shadow(none !important);
        &:focus,
        &:active,
        &:active:focus,
        &:hover {
            text-decoration: none;
            color: $color1;
        }
    }
    
    
    &.with-btn-icon {
        padding-left: 4rem;
        border: none;
        @include media-breakpoint-up(xxl) {
            padding-left: 5.45rem;
        }
        &:hover {
            .btn-icon {
                left: 0.5rem;
            }
        }
        .btn-icon {
            width: 3.125rem;
            height: 100%;
            background-color: #29265e;
            position: absolute;
            left: 0;
            top: 0;
            @extend .flex-centered;
            @include transition(350ms);
            @include border-radius(0.25rem 0 0 0.25rem);
        }
    }

}

.btn-anim {
    z-index: 1;
    border: none !important;
    @include box-shadow(none !important);
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 6px;
        z-index: -1;
        background-image: linear-gradient(to bottom, #f2a43a, #f3a93b, #f6ba40, #f9cc45);
        @include border-radius(0.25rem 0 0 0.25rem);
        @include transition(350ms);
    }
    &:focus,
    &:active,
    &:active:focus,
    &:hover {
        .color3 {
            color: #fff !important;
        }
        &:before {
            width: 100%;
            @include border-radius(0.25rem);
        }
    }

    &.theme2 {
        &:before {
            background-image: linear-gradient(to bottom, #5fd584, #5fd58d, #5fd4a3, #5fd4bd);
        }
    }

    &.theme3 {
        &:before {
            background-image: linear-gradient(to bottom, #6e5df0, #7b5ff7, #8d60f6, #9f61f6);
        }
    }

    &.theme4 {
        &:before {
            background-image: linear-gradient(to bottom, #ed4d6b, #ef695c, #f1864c, #f3a13c);
        }
    }

    &.theme5 {
        &:before {
            background: $theme-red;
        }
    }
}

.circular-btn {
    padding: 0 !important;
    min-width: inherit;
    @extend .flex-centered;
    @include sphere(2.5rem);
}

.btn-group {
    .btn {
        margin-right: 0.9375rem;
        &:last-child {
            margin-right: 0;
        }
    }
}

.btn-filters {
    @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
    }
    .second-group {
        @include media-breakpoint-up(xl) {
        width: 39%;
        }
        @include media-breakpoint-up(xxl) {
        width: 43.5%;
        }
    }
}
