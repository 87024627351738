.table {
    margin: 0;
    background: none !important;
    thead {
        th {
            background-color: $color1;
            color: $color3;
            font-size: 1rem;
            border: none;
            font-weight: normal;
            .position-relative {
                padding-right: 1rem;
            }
            &:first-child {
                @include border-radius(0.4rem 0 0 0);
            }
            &:last-child {
                @include border-radius(0 0.4rem 0 0);
            }
        }

        .th-sort {
            margin-left: 0.625rem;
            margin-top: 0.1rem;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0.2rem;
            @include transition(350ms);
            &:hover {
                color: $color4;
            }
        }
    }

    tr {
        &.has-cell-border {
            td:first-child {
                padding-left: 1.4rem;
            }
        }
    }

    &.with-table-body {
        position: relative;
    }

    &.theme2 {
        tbody {
            tr {
                td {
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
            td {
                background: none;
                border-bottom: 1px solid $color4;
                border-top: none;
            }
        }

        thead {
            tr {
                th {
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    tbody {
        tr {
            &.invisible {
                td {
                    border-top-color: transparent;
                }
            }
            &:first-child {
                td {
                    border-top-color: transparent;
                }
            }

        }
        td {
            background-color: $color4;
            border-top: 5px solid rgba($color1, 0.8);
        }
    }

    tbody td,
    thead th {
        padding: 1.07rem 0.8125rem;
        vertical-align: middle;
    }

    .table-body-cell {
        padding: 1.07rem 0.8125rem;
    }

    .fa {
        color: $color3;
        @include transition(350ms);
        &:hover {
            color: $theme-green;
        }
    }

    .fa-pencil-alt {
        font-size: 1.25rem;
    }

    .cell-border {
        width: 6px;
        height: 4.75rem;
        position: absolute;
        left: 0;
        margin-top: -1.75rem;
        &.hot-lead-border {
            background: linear-gradient(to bottom, #ed4d6b, #ef695c, #f1864c, #f3a13c);
        }
        &.message-border {
            background: linear-gradient(to bottom, #5fd584, #5fd58d, #5fd4a3, #5fd4bd);
        }
        &.progress-border {
            background: linear-gradient(to bottom, #6e5df0, #7b5ff7, #8d60f6, #9f61f6);
        }
        &.new-lead-border {
            background: linear-gradient(to bottom, #f2a43a, #f3a93b, #f6ba40, #f9cc45);
        }
    }
}


.custom-table-row {
    border-bottom: 1px solid #2b2960;
    position: relative;
    &.inner-table-active {
        background-color: #2d2a66;
    }
    /*
    &:last-child {
        border-bottom: 1px solid #2b2960;
    }*/
    .row {
        align-items: center;
    }
}

.custom-table {
    &.in-edit-mode {
        .form-control {
            background: none;
            border: none;
            height: 1.8rem;
            border-bottom: 1px solid #fff;
            color: #fff;
            @include box-shadow(none !important);
            @include border-radius(0);
        }
        .card-edit-btn {
            display: none;
        }
    }
}

.inner-table {
    position: relative;
    width: 100%;
    display: none;
    z-index: 2;

    .table-block-item {
        font-size: 0.9375rem;
        padding: 1.3rem 0.9375rem;
    }

    .inner-table-block {
        background-color: #2d2a66;
        padding: 0 0.9375rem;
        border-top: 1px solid #474494;
    }
}

.cell-btns-list,
.icons-list {
    li {
        cursor: pointer;
        font-size: 1.25rem;
        margin-left: 1.5rem;
        @include small-desktop() {
            margin-left: 1.2rem;
        }
        &:hover {
            color: $theme-green;
        }
        &:first-child {
            margin-left: 0;
        }
    }
}

.table-body-wrap,
.scroll-wrapper > .scroll-content,
.table-head-wrap {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
}

.table-body-wrap,
.scroll-wrapper > .scroll-content {
    background-color: rgba($color1, 0.6);
    padding-top: 0.625rem;
    padding-bottom: 1.875rem;
    @include border-radius( 0 0 0.4rem 0.4rem);
}

.table-body-wrap .scroll-wrapper {
    max-height: 36rem;
}


.table-head-wrap {
    background-color: $color1;
    @include border-radius(0.4rem 0.4rem 0 0);
}

.table-wrap {
    @include media-breakpoint-down(lg) {
        overflow: scroll;
        .table-head-wrap,
        .table-body-wrap {
            width: 1200px;
        }
    }
}

.table-comments {
    .comment-text {
        max-width: 9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.dashboard-table {
    .cell-border {
        height: 3.6rem;
        margin-top: -1.2rem;
    }
}

.modal-table {
    thead th {
        background-color: #2d2a66;
    }
}

.documents-table {
    td {
        width: 18%;
    }
    .scroll-content {
        background: none;
    }
}
