.modal-backdrop.show {
    opacity: 0.85;
}

.modal-header, .modal-footer {
    background-color: $color1;
    border: none;
    color: $color3;
    padding: 0.8rem 2rem;
    .modal-title {
        font-size: 1rem;
    }
    
    .close {
        font-size: 1.25rem;
        color: $color3;
        text-shadow: none;
        padding: 1.2rem;
    }
}

.modal-header {
    @include border-radius(0.5rem 0.5rem 0 0);
}

.modal-content {
    background: none;
}


.modal-body {
    background-color: rgba($color1,0.8);
    padding: 1.5rem 2rem;
    @include border-radius(0 0 0.5rem 0.5rem);
    @include media-breakpoint-down(sm) {
        padding: 1.5rem 1rem;
    }
    .modal-body-heading {
        font-size: 1.5625rem;
        margin-bottom: 0.9375rem;
    }
}

.modal-xl {
    max-width: 78.75rem;
}

.modal-xxl {
    max-width: 97.375rem;
}


.modal-open {
    padding: 0 !important;
    .modal {
        padding: 0 !important;
    }
}
