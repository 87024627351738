.site-header {
    background-color: $color1;
    color: #fff;
    padding: 0.77rem 0;
    @include box-shadow(5px 5px 10px rgba(0, 0, 0, 0.3));

    .header-panel-img {
        img {
            border: 2px solid $color2;
            @include sphere(2.875rem);
        }
    }

    .count {
        position: absolute;
        top: -0.6rem;
        right: -1rem;
        color: #fff;
        background-color: $theme-red;
        @extend .flex-centered;
        @include sphere(1.625rem);
    }

    .searchbar {
        max-width: 43%;
        @include media-breakpoint-down(md) {
            max-width: 100%;
            position: absolute;
            bottom: -4.75rem;
            left: 15px;
            width: calc(100% - 30px) !important;
        }
    }
    
    .dropdown-menu {
        margin-top: 4rem;
        @include border-radius(0 0 0.5rem 0.5rem);
        &.alerts-dropdown {
            margin-top: 3.2rem;
        }
    }
    
    
    .btn-info {
        min-width: 6.25rem;
        @include media-breakpoint-down(xs) {
            min-width: inherit;
            &.no-shadow {
                padding: 0;
            }
        }
    }
}