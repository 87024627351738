.social-icons {
    li {
        margin-left: 1.25rem;
        @include media-breakpoint-down(sm) {
            margin-left: 0.9rem;
        }
        &:first-child {
            margin-left: 0;
        }
    }
    .social-icon {
        color: #292929;
        font-size: 1.125rem;
        text-decoration: none !important;
        @include transition(350ms);
        &:hover {
            color: $theme-green;
            @include translate(0,-5px);
        }
    }
}
