.menu-trigger {
    cursor: pointer;
    z-index: 101;
    position: relative;
    @include transition(350ms);
    .line {
        height: 2px;
        width: 1.875rem;
        margin-bottom: 0.5rem;
        background-color: #ffffff;
        display: block;
        position: relative;
        @include transition(all 0.15s cubic-bezier(0.63, 0.62, 0.48, 1.64) 0.1s);
        @include transform(rotateZ(0deg) translateX(0px) translateY(0px));
        &:last-child {
            margin-bottom: 0;
        }
        &.second {
            width: 1.5rem;
            @include transform(none);
        }
    }
    /*&.active {
        .line {
            &.second {
                opacity: 0;
            }
            &.first {
                @include transform(rotateZ(50deg) translateX(11px) translateY(9px) !important);
            }
            &.third {
                @include transform(rotateZ(-49deg) translateX(7px) translateY(-6px) !important);
            }
        }
    }*/
}