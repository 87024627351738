.ui-datepicker {
    padding-top: 15px;
    background-color: $color1;
    text-align: center;
    padding: 1.5rem;
    z-index: 9999 !important;;
    color: #fff;
    display: none;
    @include border-radius(0);
    a {
        color: #fff !important;
    }
    .ui-widget-header {
        background: none;
        border: none;
        font-weight: normal;
        .ui-datepicker-title {
            line-height: 1;
            margin-bottom: 5px;
            color: #fff;
        }
        .ui-corner-all {
            padding: 0;
            width: 0.625rem;
            height: 1.125rem;
            cursor: pointer;
            background-repeat: no-repeat;
            border: none !important;
            position: absolute;
            top: 10px;
            background-size: 100%;
            &.ui-datepicker-prev {
                left: 12px;
                background-image: url(../images/icons/arrow-left-white.svg) !important;
            }
            &.ui-datepicker-next {
                background-image: url(../images/icons/arrow-right-white.svg) !important;
                right: 12px;
            }
            .ui-icon {
                display: none;
            }
        }
    }
    .ui-datepicker-calendar {
        position: relative;
        left: -7px;
        .ui-state-default {
            border: none;
            outline: none;
            background: none;
            font-size: 13px;
            text-align: center;
            padding-top: 7px;
            display: block;
            text-indent: 1px;
            margin: 0 auto;
            @include sphere(30px);
        }
        td {
            padding: 0.2rem;
        }
        .ui-state-active {
            background: $color2;
            color: #fff;
        }
        .ui-state-disabled {
            opacity: 1;
            span {
                background: #f1f1f1;
                display: inline-block;
                color: $text-color;
            }
        }
    }
}

.datepicker-wrap {
    position: relative;
    .date-icon {
        position: absolute;
        right: 1rem;
        top: 0;
    }
    .form-control {
        position: relative;
        padding-right: 1.3rem;
        z-index: 2;
    }
}
