#main-navigation {
    @include media-breakpoint-down(lg) {
        z-index: 100;
        position: fixed;
        background-color: $color1;
        right: 0;
        top: -100%;
        width: 100%;
        height: 100%;
        @include box-shadow(-4px 0 20px rgba(0, 0, 0, 0.2));
        @include transition(350ms);
        &.active {
            top: 4.4rem;
        }
    }
    @include media-breakpoint-down(xs) {
        &.active {
            top: 3.6rem;
        }
    }

    .nav-menu {

        @include media-breakpoint-down(lg) {
            text-align: center !important;
            overflow-y: auto;
            height: 100%;
            display: block;
        }

        > li {
            padding: 0;
            margin-left: 1.875rem;
            @include media-breakpoint-down(lg) {
                margin-left: 0;
                border-top: 1px solid #24282e;
            }
            &:first-child {
                margin-left: 0;
            }

            > a {
                font-size: 0.875rem;
                display: inline-block;
                text-transform: uppercase;
                position: relative;
                font-weight: 700;
                color: #ffffff;
                padding: 0.68rem 1.15rem;
                @include transition(350ms);
                &.highlight-link {
                    @include media-breakpoint-up(xl) {
                        background-color: $color1;
                        color: $theme-green;
                        &:hover {
                            color: #fff;
                            background-color: #71767c;
                        }
                    }
                }
                @include media-breakpoint-down(lg) {
                    color: #fff;
                    font-size: 25px;
                    padding: 2.5rem 1.5rem;
                }
                @include media-breakpoint-down(xs) {
                    padding: 2rem 1rem;
                    font-size: 20px;
                }
            }
        }
    }
}
