*,
*:after,
*:before {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

::-moz-selection {
    background: $color1;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: $color1;
    color: #fff;
    text-shadow: none;
}


html,
body {
    font-size: 15.5px;
    line-height: 1.3;
    font-family: $FontStack;
    color: #fff;
    overflow-x: hidden;
    @include media-breakpoint-up(xxl) {
        font-size: 16px;
    }
}

body {
    background: url(../images/body-bg.jpg) no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
}



hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $border-color;
    margin: 2rem 0;
    padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    line-height: 1.3;
}

a {
    color: $color1;
    text-decoration: none;
    line-height: inherit;
    &:hover {
        color: $color1;
        text-decoration: none;
    }
    &:focus,
    &:hover,
    &:active {
        outline: none;
        text-decoration: none;
    }
}


strong, b {
    font-weight: 700;
}


blockquote {
    margin: 0;
    padding: 0;
    border-left: none;
}

img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
    &.aligncenter {
        margin-right: auto;
        margin-left: auto;
        display: block;
        margin-bottom: 10px;
    }
    &.alignleft {
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    &.alignright {
        float: right;
        margin-left: 10px;
        margin-bottom: 10px;
    }
}

// Media Query
@mixin small-desktop {
    @media (max-width: 1400px) and (min-width: 1200px) {
        @content;
    }
}

.scrolling {
    .product-filter, .site-popup.stick-right {
        top: 3rem;
    }
}


.single-item-page {
    @include media-breakpoint-up(md) {
    height: calc(100vh - 4.98125rem);
    }
}


.container-fluid {
    @include media-breakpoint-up(xl) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    @include media-breakpoint-up(xxl) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.container {
    max-width: 75.625rem;
    width: 100%;

    &.is-big {
        max-width: 85rem;
        @include media-breakpoint-up(xxl) {
            max-width: 88.375rem;
        }
    }
}
